.modaloverlay {
  background: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  transition: all 0.5s ease;
  z-index: 10;
  .modal {
    background-color: white;
    max-height: 80vh;
    min-height: 75vh;
    position: relative;
    margin: 15% auto;
    padding: 3em;
    overflow-y: scroll;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media (min-width: 60em) {
      margin: 5% auto;
      max-height: 75em;
      max-width: 66em;
      width: 65%;
    }
    > iframe,
    > div {
      border: none;
      width: 100%;
      height: 100%;
    }
  }
  .close {
    background-color: turquoise;
    color: white;
    font-size: 24px;
    padding: 8px 12px;
    position: absolute;
    right: 0;
    text-align: center;
    text-decoration: none;
    top: 0;
    z-index: 1;
  }
}

.addSong {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .button {
    background: turquoise;
    padding: 11px 50px;
    border-color: turquoise;
    color: #fff;
    text-align: center;

    font-size: 20px;
    border: 1px solid turquoise;
    border-radius: 10px;

    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;

    box-sizing: border-box;
  }

  .inputBox {
    width: 100%;
    padding: 15px;
    border-radius: 13px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-size: 18px;
    text-align: center;
    margin: 10px 0;
    @media (min-width: 60em) {
      width: 70%;
    }
  }

  .songCover {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: block;
    align-self: center;
    margin: 10px 0;
  }

  .file-name {
    position: relative;
    overflow: hidden;
    line-height: 30px;
    padding: 5px;
    box-sizing: border-box;
    font-size: 15px;
    vertical-align: middle;
    width: 70%px;
    border: 2px solid #dbdbdb;
    border-radius: 12px;
    height: calc(2em + 0.75rem + 2px);
    width: 210px;
    overflow: hidden;
    @media (min-width: 60em) {
      width: 365px;
    }
  }

  .btn {
    border: none;
    border-radius: 4px;

    box-sizing: border-box;
    padding: 2px 10px;

    transition: all 0.6s;

    font-size: 15px;
    vertical-align: middle;

    margin-left: 1rem;
  }

  .check {
    font-size: 18px;
    input {
      height: 16px;
      width: 16px;
      margin-top: 3px;
    }
    margin-bottom: 15px;
  }
}
.d-flex {
  display: flex;
}
.mv-10 {
  margin: 10px 0px;
}
.noSong {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 30%;

  button {
    padding: 0.5rem 4rem;
    margin-top: 15px;
    border-radius: 10rem;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: black;
      color: white;
    }
  }

  @media (min-width: 60em) {
    margin-top: 5%;
  }
}
